 
$( 'img.lazy' ).lazyload({
  threshold: 2000 ,			// 200pxの距離まで近づいたら表示する
  effect: "fadeIn" ,		// じわじわと表示させる
  effect_speed: 0 ,		// 3秒かけて表示させる
});

$('[data-fancybox]').fancybox();

$('.slider').slick({
  infinite: false,
  arrows: true,
});

$('.mv_sld').slick({
  infinite: true,
  arrows: false,
  slidestoshow: 1,
  autoplay: true,
});

if (matchMedia('only screen and (max-width: 767px)').matches) {
//スマホ・タブレットの時の処理
  $(function () {
    $(window).scroll(function () {
      $('.Fade-In,.Fade-In-Down,.Fade-In-Left,.Fade-In-Right').each(function () {
      var targetElement = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > targetElement - windowHeight + -1000) {
        $(this).css('opacity', '1');
        $(this).css('transform', 'translateY(0)');
      }
      });
    });
  });
  $(".sp_none").removeClass("Fade-In");
} else if (matchMedia('only screen and (max-width: 991px)').matches) {
  //タブレットの時の処理
  $(function () {
    $(window).scroll(function () {
      $('.Fade-In,.Fade-In-Down,.Fade-In-Left,.Fade-In-Right').each(function () {
        var targetElement = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > targetElement - windowHeight + -300) {
          $(this).css('opacity', '1');
          $(this).css('transform', 'translateY(0)');
        }
      });
    });
  });
} else {
//PCの時の処理
  $(function () {
    $(window).scroll(function () {
      $('.Fade-In,.Fade-In-Down,.Fade-In-Left,.Fade-In-Right').each(function () {
        var targetElement = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > targetElement - windowHeight + 100) {
          $(this).css('opacity', '1');
          $(this).css('transform', 'translateY(0)');
        }
      });
    });
  });
};

$('.sp_menu').on('click', function(){
  $(this).stop().toggleClass('active');
  $('.g_nav').stop().toggleClass('active').slideToggle();
});

$('.dl_faq dt').on('click', function(){
  $(this).toggleClass('active').next('dd').stop().slideToggle();
});
